import { useEffect } from 'react';

const SectionScroll = () => {
  const handleScrollToAnchor = (e: Event) => {
    e.preventDefault();
    const anchor = e.currentTarget as HTMLAnchorElement;
    const href = anchor.getAttribute('href') ?? '';
    const targetId = href.split('#')[1]; // Extracting the section ID after the '#'
    const targetElement = document.getElementById(targetId ?? '');

    if (targetElement) {
      if (window.outerWidth < 992) {
        window.scrollTo({
          top: targetElement.offsetTop - 40,
          behavior: 'smooth',
        });
      } else {
        window.scrollTo({
          top: targetElement.offsetTop,
          behavior: 'smooth',
        });
      }
    }
  };

  useEffect(() => {
    const scrollLinks = document.querySelectorAll('.section_scroll');
    scrollLinks.forEach((link) => {
      link.addEventListener('click', handleScrollToAnchor);
    });

    return () => {
      scrollLinks.forEach((link) => {
        link.removeEventListener('click', handleScrollToAnchor);
      });
    };
  }, []);

  return null; // This component doesn't render anything
};

export default SectionScroll;
